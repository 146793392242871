import React, { useState } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'shards-react';

export default function TailwindLogo({ id }) {
  const [isOpen, setIsOpen] = useState(false);
  const someStyles = {
    maxWidth: '70px',
  };
  return (
    <a
      href="https://tailwindcss.com/"
      target="_blank"
      className="icon"
      key={id}
      id={`mongodb-logo-${id}`}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <svg
        style={someStyles}
        preserveAspectRatio="xMidYMid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 153.6"
      >
        <linearGradient id="a" x1="-2.778%" y1="32%" y2="67.556%">
          <stop offset="0" stopColor="#2298bd" />
          <stop offset="1" stopColor="#0ed7b5" />
        </linearGradient>
        <path
          d="M128 0C93.867 0 72.533 17.067 64 51.2 76.8 34.133 91.733 27.733 108.8 32c9.737 2.434 16.697 9.499 24.401 17.318C145.751 62.057 160.275 76.8 192 76.8c34.133 0 55.467-17.067 64-51.2-12.8 17.067-27.733 23.467-44.8 19.2-9.737-2.434-16.697-9.499-24.401-17.318C174.249 14.743 159.725 0 128 0zM64 76.8C29.867 76.8 8.533 93.867 0 128c12.8-17.067 27.733-23.467 44.8-19.2 9.737 2.434 16.697 9.499 24.401 17.318C81.751 138.857 96.275 153.6 128 153.6c34.133 0 55.467-17.067 64-51.2-12.8 17.067-27.733 23.467-44.8 19.2-9.737-2.434-16.697-9.499-24.401-17.318C110.249 91.543 95.725 76.8 64 76.8z"
          fill="url(#a)"
        />
      </svg>
      <Popover placement="bottom" open={isOpen} target={`#mongodb-logo-${id}`}>
        <PopoverHeader className="popover-header">TailwindCSS</PopoverHeader>
        <PopoverBody className="popover-body">
          A utility-first CSS framework for rapidly building custom designs.
          <br />
          <br />
          Tailwind CSS is a highly customizable, low-level CSS framework that gives you all of the
          building blocks you need to build bespoke designs without any annoying opinionated styles
          you have to fight to override.
        </PopoverBody>
      </Popover>
    </a>
  );
}
