import React, { useState } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'shards-react';

export default function CssLogo({ id }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <a
      href="https://developer.mozilla.org/en-US/docs/Web/CSS"
      target="_blank"
      className="icon"
      id={`css-logo-${id}`}
      key={id}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <path
          fill="#131313"
          d="M89.234 5.856h-7.384l7.679 8.333v3.967h-15.816v-4.645h7.678l-7.678-8.333v-3.971h15.521v4.649zm-18.657 0h-7.384l7.679 8.333v3.967h-15.817v-4.645h7.679l-7.679-8.333v-3.971h15.522v4.649zm-18.474.19h-7.968v7.271h7.968v4.839h-13.632v-16.949h13.632v4.839z"
        />
        <path
          fill="#1572B6"
          d="M27.613 116.706l-8.097-90.813h88.967l-8.104 90.798-36.434 10.102-36.332-10.087z"
        />
        <path fill="#33A9DC" d="M64.001 119.072l29.439-8.162 6.926-77.591h-36.365v85.753z" />
        <path
          fill="#fff"
          d="M64 66.22h14.738l1.019-11.405h-15.757v-11.138h27.929000000000002l-.267 2.988-2.737 30.692h-24.925v-11.137z"
        />
        <path
          fill="#EBEBEB"
          d="M64.067 95.146l-.049.014-12.404-3.35-.794-8.883h-11.178999999999998l1.561 17.488 22.814 6.333.052-.015v-11.587z"
        />
        <path
          fill="#fff"
          d="M77.792 76.886l-1.342 14.916-12.422 3.353v11.588l22.833-6.328.168-1.882 1.938-21.647h-11.175z"
        />
        <path
          fill="#EBEBEB"
          d="M64.039 43.677v11.136999999999999h-26.903000000000002l-.224-2.503-.507-5.646-.267-2.988h27.901zM64 66.221v11.138h-12.247l-.223-2.503-.508-5.647-.267-2.988h13.245z"
        />
      </svg>
      <Popover placement="bottom" open={isOpen} target={`#css-logo-${id}`}>
        <PopoverHeader className="popover-header">CSS3</PopoverHeader>
        <PopoverBody className="popover-body">
          Cascading Style Sheets (CSS) is a stylesheet language used to describe the presentation of
          a document written in HTML or XML (including XML dialects such as SVG, MathML or XHTML).
          CSS describes how elements should be rendered on screen, on paper, in speech, or on other
          media.
        </PopoverBody>
      </Popover>
    </a>
  );
}
